import http from '@/apis';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { InfoCustomer } from '@/dto/customer/createNewCustomer.dto';
import { AdminUpdateUser } from '@/dto/settings/AdminUpdateUser.dto';
import { SettingsUser } from '@/dto/settings/SettingsUser.dto';
import { CreateUser } from '@/dto/users/CreateUser.dto';
import { InfoContactPerson } from '@/dto/users/InfoContactPerson.dto';
import { InfoInviteUser } from '@/dto/users/InfoInviteUser.dto';
import { InfoUser } from '@/dto/users/InfoUser.dto';
import { prepareGetQuery } from '@utils/api.utils';

class UserService {
  prefix = 'users';
  prefixAdmin = 'admin/' + this.prefix;

  public async getUser(): Promise<InfoUser> {
    const result = await http.get(`${this.prefix}/`);
    return result.data.result;
  }

  public async getUserById(id: number): Promise<InfoUser> {
    const result = await http.get(`${this.prefixAdmin}/${id}`);
    return result.data.result;
  }

  public async getUsers(
    query?: PaginationRequest<InfoUser>
  ): Promise<PaginationResult<SettingsUser>> {
    let url = this.prefixAdmin;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);
    return result.data.result;
  }

  public async getCompanyUsers(
    id: number,
    query?: PaginationRequest
  ): Promise<SettingsUser[]> {
    let url = this.prefixAdmin;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);
    const users = result.data.result.data;
    const companyUsers = users.filter((user) => user.companyId === id);

    return companyUsers;
  }

  public async updateUser(userInfo: InfoUser): Promise<boolean> {
    const result = await http.put(`${this.prefix}/`, userInfo);
    return result.data.result;
  }

  public async updateAnotherUser(userInfo: InfoUser): Promise<boolean> {
    const result = await http.put(`${this.prefixAdmin}/`, userInfo);
    return result.data.result;
  }

  public async registerUser(model: CreateUser): Promise<boolean> {
    const result = await http.post(`${this.prefix}`, model);
    return result.data.result;
  }

  public async adminUpdateUser(model: AdminUpdateUser): Promise<boolean> {
    const result = await http.put(`${this.prefixAdmin}/`, model);
    return result.data.result;
  }

  public async inviteUser(model: InfoInviteUser): Promise<number> {
    const result = await http.post(`${this.prefixAdmin}/invite-user`, model);
    return result.data.result;
  }

  public async createContactPerson(model: InfoContactPerson): Promise<boolean> {
    const result = await http.post(`${this.prefixAdmin}/contact-person`, model);
    return result.data.result;
  }

  public async getUsersByCompany(companyId: number): Promise<InfoUser[]> {
    const result = await http.get(`${this.prefix}/company/${companyId}`);
    return result.data.result;
  }

  public async approveUser(userId: number): Promise<boolean> {
    const result = await http.put(`${this.prefix}/approve-user/${userId}`);
    return result.data.result;
  }

  public async handleRequestAccount(model: InfoCustomer): Promise<boolean> {
    const result = await http.post(`${this.prefix}/request-user/`, model);
    return result.data.result;
  }
}

export default new UserService();
