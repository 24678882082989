import tripService from '@/apis/trip.service';
import { ActivitiesResult } from '@/dto/mass/activities/ActivitiesResult.dto';
import { InfoTrip } from '@/dto/trip/InfoTrip.dto';
import { PaginationRequest } from '@dto/commons/PaginationRequest.dto';
import { MASS_TYPE } from '@enums/mass.enum';
import { action, makeObservable, observable } from 'mobx';

class TripStore {
  currentViewTripTransaction: ActivitiesResult | null;
  tripList: InfoTrip[];
  tripsByProject: InfoTrip[];
  tripsByProjectCount: number;
  tripsByUser: InfoTrip[];
  tripsByUserCount: number;
  latestTripsByMass: InfoTrip[];
  totalCount: number;
  remainTransactionVolume: number;

  constructor() {
    this.tripList = [];
    this.tripsByProject = [];
    this.tripsByProjectCount = 0;
    this.tripsByUser = [];
    this.tripsByUserCount = 0;
    this.latestTripsByMass = [];
    this.currentViewTripTransaction = null;
    this.totalCount = 0;
    this.remainTransactionVolume = 0;

    makeObservable(this, {
      tripList: observable,
      tripsByProject: observable,
      tripsByProjectCount: observable,
      tripsByUser: observable,
      tripsByUserCount: observable,
      latestTripsByMass: observable,
      currentViewTripTransaction: observable,
      totalCount: observable,
      remainTransactionVolume: observable,
      setTripList: action,
      setTripTotalCount: action,
      setCurrentViewTripTransaction: action,
      setRemainTransactionVolume: action,
      getTripsByProject: action,
      getTripsByUser: action,
      getLatestTripsByMass: action,
    });
  }

  async registerTrip(model: InfoTrip, file: File | null): Promise<boolean> {
    return await tripService.registerTrip(model, file);
  }

  async getTripById(id: number): Promise<InfoTrip> {
    return await tripService.getTripById(id);
  }

  async getTripsByProject(
    projectId: number,
    query?: PaginationRequest<InfoTrip>
  ): Promise<void> {
    const result = await tripService.getTripsByProject(projectId, query);
    this.tripsByProject = result.data;
    this.tripsByProjectCount = result.count;
  }

  async getTripsByUser(query?: PaginationRequest<InfoTrip>): Promise<void> {
    const result = await tripService.getTripsByUser(query);
    this.tripsByUser = result.data;
    this.tripsByUserCount = result.count;
  }

  async getLatestTripsByMass(
    massId: number,
    massType: MASS_TYPE
  ): Promise<void> {
    const data = await tripService.getLatestTripsByMass(massId, massType);
    this.latestTripsByMass = data;
  }

  async updateTrip(model: InfoTrip, file: File | null): Promise<boolean> {
    return await tripService.updateTrip(model, file);
  }

  async getAllTransactionTrips(id: number): Promise<InfoTrip[]> {
    const result = await tripService.getAllTransactionTrips(id);
    return result.data;
  }

  setTripList(data: InfoTrip[]) {
    this.tripList = data;
  }

  setTripTotalCount(total: number) {
    this.totalCount = total;
  }

  setCurrentViewTripTransaction(item: ActivitiesResult) {
    this.currentViewTripTransaction = item;
  }

  setRemainTransactionVolume(volume: number) {
    this.remainTransactionVolume = volume;
  }
}

export default TripStore;
