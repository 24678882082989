import userService from '@/apis/user.service';
import { InfoUser } from '@/dto/users/InfoUser.dto';
import { USER_TYPE, USER_ROLE } from '@/enums/user.enum';
import { action, makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import {
  INIT_USER_FORM_ERROR,
  INIT_USER_FORM,
} from '@/constants/user.constants';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { SettingsUser } from '@/dto/settings/SettingsUser.dto';
import { AdminUpdateUser } from '@/dto/settings/AdminUpdateUser.dto';
import companyService from '@/apis/company.service';
import { InfoInviteUser } from '@/dto/users/InfoInviteUser.dto';
import { InfoContactPerson } from '@/dto/users/InfoContactPerson.dto';
import { InfoCustomer } from '@/dto/customer/createNewCustomer.dto';

class UserStore {
  currentUser: InfoUser;
  errorUserForm: Record<string, boolean>;
  profileType: USER_TYPE;
  users: SettingsUser[];
  userCount: number;
  companyUsers: SettingsUser[];

  constructor() {
    this.currentUser = INIT_USER_FORM;
    this.errorUserForm = INIT_USER_FORM_ERROR;
    this.profileType = USER_TYPE.PERSON;
    this.users = [];
    this.userCount = 0;
    this.companyUsers = [];

    makeObservable(this, {
      currentUser: observable,
      errorUserForm: observable,
      profileType: observable,
      users: observable,
      userCount: observable,
      companyUsers: observable,
      getUser: action,
      getUserById: action,
      getUsers: action,
      submitProfileForm: action,
      resetProfileForm: action,
      setProfileType: action,
      registerUser: action,
      setCurrentUserInit: action,
      adminUpdateUser: action,
      getCompanyUsers: action,
      resetProfileType: action,
      approveUser: action,
      inviteUser: action,
    });
  }

  async getUser(): Promise<void> {
    const data = await userService.getUser();
    this.currentUser = data;
  }

  async getUserById(id: number): Promise<void> {
    const data = await userService.getUserById(id);
    this.currentUser = data;
  }

  async getUsers(query?: PaginationRequest<InfoUser>): Promise<void> {
    const data = await userService.getUsers(query);
    this.users = data.data;
    this.userCount = data.count;
  }

  async getCompanyUsers(id: number, query?: PaginationRequest): Promise<void> {
    const data = await userService.getCompanyUsers(id, query);
    this.companyUsers = data;
  }

  registerUser = async (user: InfoUser): Promise<boolean> => {
    const result = userService.registerUser(user);
    return result;
  };

  submitProfileForm = async (
    user: InfoUser,
    ownProfile: boolean,
    regetUser?: boolean
  ): Promise<boolean> => {
    const result = ownProfile
      ? await userService.updateUser(user)
      : await userService.updateAnotherUser(user);
    if (result) {
      if (!!regetUser) {
        this.getUser();
      }
      toast.success('Profile saved');
      return true;
    } else {
      toast.error('Error when saving profile');
      return false;
    }
  };

  resetProfileForm = async (id?: number): Promise<void> => {
    if (id) await this.getUserById(id);
    else await this.getUser();
  };

  setProfileType = (value: USER_TYPE) => {
    this.profileType = value;
  };

  setCurrentUserInit = () => {
    this.currentUser = INIT_USER_FORM;
  };

  resetProfileType = () => {
    this.profileType = USER_TYPE.PERSON;
  };

  async adminUpdateUser(model: AdminUpdateUser): Promise<void> {
    await userService.adminUpdateUser(model);

    if (model.role === USER_ROLE.COMPANY) {
      await companyService.updateOwnerId(model.companyId, model.id);
    }

    const users = [...this.users];
    const index = this.users.findIndex((x) => x.id == model.id);
    users[index] = { ...users[index], ...model };
    this.users = users;
  }

  async inviteUser(model: InfoInviteUser): Promise<number> {
    const result = await userService.inviteUser(model);
    this.users = [{ ...model, id: result } as SettingsUser, ...this.users];
    return result;
  }

  async createContactPerson(model: InfoContactPerson): Promise<boolean> {
    const result = await userService.createContactPerson(model);
    return result;
  }

  async approveUser(userId: number): Promise<boolean> {
    const result = await userService.approveUser(userId);
    return result;
  }

  async handleRequestAccount(model: InfoCustomer): Promise<boolean> {
    const result = await userService.handleRequestAccount(model);
    return result;
  }
}

export default UserStore;
