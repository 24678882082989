import massService from '@/apis/mass.service';
import { INIT_REQUEST, INIT_SUPPLY } from '@/constants/mass.constants';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { SelectOption } from '@/dto/commons/SelectOption.dto';
import { ActivitiesResult } from '@/dto/mass/activities/ActivitiesResult.dto';
import { RegisteredPagination } from '@/dto/mass/activities/paginations/RegisteredPagination.dto';
import { TransactionPagination } from '@/dto/mass/activities/paginations/TransactionPagination.dto';
import { SearchExchange } from '@/dto/mass/exchange/SearchExchange.dto';
import { RegisterRequestDto } from '@/dto/mass/request/RegisterRequest.dto';
import { Request } from '@/dto/mass/request/Request.dto';
import { SearchRequest } from '@/dto/mass/request/SearchRequest.dto';
import { CreateUpdateTransaction } from '@/dto/mass/reservation/Reservation.dto';
import { SearchMass } from '@/dto/mass/searchMass.dto';
import { RegisterSupplyDto } from '@/dto/mass/supply/RegisterSupply.dto';
import { SearchSupply } from '@/dto/mass/supply/SearchSupply.dto';
import { Supply } from '@/dto/mass/supply/Supply.dto';
import {
  ACTIVITIES_RESULT_STATUS,
  ACTIVITIES_RESULT_TYPE,
} from '@/enums/mass.enum';
import { resetTimeZeroOfDate } from '@utils/date.utils';
import { getLanguageCurrent } from '@utils/language.utils';
import { handleDebounce } from '@utils/time.util';
import { action, computed, makeObservable, observable } from 'mobx';

class MassStore {
  registerSupplyDto: Supply;
  supplyList: Supply[];
  supplyCount: number;
  currentSupply: Supply;
  requestList: Request[];
  requestCount: number;
  currentRequest: Request;
  isUpdateSupply: boolean;
  isUpdateRequest: boolean;
  minifiedExchange: (Supply | Request)[];
  activitiesList: Request[];
  transactionFormIsOpen: boolean;
  activityRegisteredList: ActivitiesResult[];
  totalActivityRegisteredList: number;
  activityPlannedList: ActivitiesResult[];
  totalActivityPlannedList: number;
  activityCompletedList: ActivitiesResult[];
  totalActivityCompletedList: number;
  relatedTransactions: ActivitiesResult[];
  numberOfRelatedTransactions: number;
  supplySearchResult: Supply[];
  requestSearchResult: Request[];
  exchangeList: (Supply | Request)[];
  exchangeSearchResult: (Supply | Request)[];
  massesToTransfer: Supply[];

  timeOut: NodeJS.Timeout | null = null;

  constructor() {
    this.registerSupplyDto = INIT_SUPPLY;
    this.supplyList = [];
    this.supplyCount = 0;
    this.currentSupply = INIT_SUPPLY;
    this.requestList = [];
    this.requestCount = 0;
    this.currentRequest = INIT_REQUEST;
    this.isUpdateSupply = false;
    this.isUpdateRequest = false;
    this.minifiedExchange = [];
    this.activitiesList = [];
    this.transactionFormIsOpen = false;
    this.activityRegisteredList = [];
    this.totalActivityRegisteredList = 0;
    this.activityPlannedList = [];
    this.totalActivityPlannedList = 0;
    this.activityCompletedList = [];
    this.totalActivityCompletedList = 0;
    this.supplySearchResult = [];
    this.requestSearchResult = [];
    this.exchangeList = [];
    this.exchangeSearchResult = [];
    this.relatedTransactions = [];
    this.numberOfRelatedTransactions = 0;
    this.massesToTransfer = [];

    makeObservable(this, {
      registerSupplyDto: observable,
      supplyList: observable,
      supplyCount: observable,
      currentSupply: observable,
      requestList: observable,
      requestCount: observable,
      currentRequest: observable,
      isUpdateSupply: observable,
      isUpdateRequest: observable,
      minifiedExchange: observable,
      activitiesList: observable,
      transactionFormIsOpen: observable,
      activityRegisteredList: observable,
      totalActivityRegisteredList: observable,
      activityPlannedList: observable,
      totalActivityPlannedList: observable,
      activityCompletedList: observable,
      totalActivityCompletedList: observable,
      supplySearchResult: observable,
      requestSearchResult: observable,
      exchangeList: observable,
      exchangeSearchResult: observable,
      relatedTransactions: observable,
      numberOfRelatedTransactions: observable,
      massesToTransfer: observable,
      getAllSupply: action,
      getAllRequest: action,
      setIsUpdateSupply: action,
      setIsUpdateRequest: action,
      setTransactionFormIsOpen: action,
      setSupplySearchResult: action,
      setRequestSearchResult: action,
      setExchangeSearchResult: action,
      getActivityPlannedList: action,
      getActivityRegisteredList: action,
      getRelatedTransactions: action,
      searchMassToTransfer: action,
      activitiesRegistered: computed,
      activitiesPlanned: computed,
      activitiesCompleted: computed,
      transferMassOptions: computed,
    });
  }

  async getAllSupplyMinify(): Promise<void> {
    const data = await massService.getAllSupplyMinify();
    this.supplyList = data;
  }

  async getAllRequestMinify(): Promise<void> {
    const data = await massService.getAllRequestMinify();
    this.requestList = data;
  }

  async getAllExchangeMinify(): Promise<void> {
    const data = await massService.getAllExchangeMinify();
    this.exchangeList = data;
  }

  async getAllSupply(): Promise<void> {
    const data = await massService.getAllSupply();
    this.supplyList = data;
  }
  async getAllRequest(): Promise<void> {
    const data = await massService.getAllRequest();
    this.requestList = data;
  }

  async getActivitiesByCompanyId(id: number): Promise<void> {
    const data = await massService.getActivitiesByCompanyId(id);
    this.activitiesList = data;
  }

  async registerSupply(model: RegisterSupplyDto): Promise<boolean> {
    const result = await massService.registerSupply(model);
    return result;
  }

  async registerRequest(model: RegisterRequestDto): Promise<boolean> {
    const result = await massService.registerRequest(model);
    return result;
  }

  async getSupplyByUserId(query: PaginationRequest) {
    const { data, count } = await massService.getSupplyByUserId(query);
    this.setSupplyList(data);
    this.setSupplyCount(count);
  }

  async getRequestByUserId(query: PaginationRequest) {
    const { data, count } = await massService.getRequestByUserId(query);
    this.setRequestList(data);
    this.setRequestCount(count);
  }

  async getSupplyInfo(id: number) {
    const result = await massService.getSupplyInfo(id);
    this.setCurrentSupply(result);
  }

  async getRequestInfo(id: number) {
    const result = await massService.getRequestInfo(id);
    this.setCurrentRequest(result);
  }

  async updateSupplyStatus(id: number, status: ACTIVITIES_RESULT_STATUS) {
    return await massService.updateSupplyStatus(id, status);
  }

  async updateRequestStatus(id: number, status: ACTIVITIES_RESULT_STATUS) {
    return await massService.updateRequestStatus(id, status);
  }

  async updateSupplyNotification(id: number, receiveNotifications: boolean) {
    return await massService.updateSupplyNotification(id, receiveNotifications);
  }

  async updateRequestNotification(id: number, receiveNotifications: boolean) {
    return await massService.updateRequestNotification(
      id,
      receiveNotifications
    );
  }

  async updateSupply(id: number, model: RegisterSupplyDto) {
    const result = await massService.updateSupply(id, model);
    return result;
  }

  async updateRequest(id: number, model: RegisterRequestDto) {
    const result = await massService.updateRequest(id, model);
    return result;
  }

  async getMinifiedExchange(model: SearchMass) {
    const result = await massService.getMinifiedExchange(model);
    this.setMinifiedExchange(result);
  }

  // async getFilterMinifiedExchange(model: SearchMass) {
  //   const result = await massService.getMinifiedExchange(model);
  //   this.setMinifiedExchange(result);
  //   return result;
  // }

  async searchSupply(model: SearchSupply): Promise<Supply[]> {
    const searchedSupplies = await massService.searchSupply(model);
    this.setSupplySearchResult(searchedSupplies);
    return searchedSupplies;
  }

  async searchRequest(model: SearchRequest): Promise<Request[]> {
    const searchedRequests = await massService.searchRequest(model);
    this.setRequestSearchResult(searchedRequests);
    return searchedRequests;
  }

  async searchExchange(model: SearchExchange): Promise<(Request | Supply)[]> {
    const searchedExchanges = await massService.searchExchange(model);
    this.setExchangeSearchResult(searchedExchanges);
    return searchedExchanges;
  }

  async updateTransaction(
    id: number,
    type: ACTIVITIES_RESULT_TYPE,
    volume: number,
    transportDate: Date
  ): Promise<boolean> {
    let result = false;
    if (type === ACTIVITIES_RESULT_TYPE.RESERVATION) {
      result = await massService.updateMassReservation(
        id,
        volume,
        transportDate
      );
    } else if (type === ACTIVITIES_RESULT_TYPE.OFFER) {
      result = await massService.updateMassOffer(id, volume, transportDate);
    }

    return result;
  }

  async deleteTransaction(
    id: number,
    type: ACTIVITIES_RESULT_TYPE
  ): Promise<boolean> {
    let result = false;
    if (type === ACTIVITIES_RESULT_TYPE.RESERVATION) {
      result = await massService.deleteMassReservation(id);
    } else if (type === ACTIVITIES_RESULT_TYPE.OFFER) {
      result = await massService.deleteMassOffer(id);
    }

    return result;
  }

  setMinifiedExchange(data: any[]) {
    this.minifiedExchange = data;
  }

  setSupplyList(data: Supply[]) {
    this.supplyList = data;
  }

  setSupplyCount(count: number) {
    this.supplyCount = count;
  }

  setCurrentSupply(data: Supply) {
    this.currentSupply = data;
  }

  setRequestList(data: Request[]) {
    this.requestList = data;
  }

  setRequestCount(count: number) {
    this.requestCount = count;
  }

  setCurrentRequest(data: Request) {
    this.currentRequest = data;
  }

  setIsUpdateSupply(isUpdateSupply: boolean): void {
    this.isUpdateSupply = isUpdateSupply;
  }

  setIsUpdateRequest(isUpdateRequest: boolean): void {
    this.isUpdateRequest = isUpdateRequest;
  }

  setCustomTimeout(value: NodeJS.Timeout) {
    this.timeOut = value;
  }

  setTransactionFormIsOpen(value: boolean): void {
    this.transactionFormIsOpen = value;
  }

  setSupplySearchResult(data: Supply[]): void {
    this.supplySearchResult = data;
  }

  setRequestSearchResult(data: Request[]): void {
    this.requestSearchResult = data;
  }

  setExchangeSearchResult(data: (Request | Supply)[]): void {
    this.exchangeSearchResult = data;
  }

  debounceSearchExchange(filter: SearchMass) {
    handleDebounce(
      this.timeOut,
      () => this.getMinifiedExchange(filter),
      500,
      (value: NodeJS.Timeout) => this.setCustomTimeout(value)
    );
  }

  debounceSearchMassToTransfer(projectId: number, searchKey: string) {
    handleDebounce(
      this.timeOut,
      () => this.searchMassToTransfer(projectId, searchKey),
      500,
      (value: NodeJS.Timeout) => this.setCustomTimeout(value)
    );
  }

  get exchangeOptions(): SelectOption[] {
    const currentLanguage = getLanguageCurrent();
    return this.minifiedExchange.map((s) => {
      const categoryName =
        s.translations?.find(
          (translation) => translation.languageCode === currentLanguage
        )?.name ?? s.translations[0]?.name;

      return {
        label: `${categoryName ?? ''}, ${s.materialName}`,
        volume: s.volume,
        value: s.id || 0,
        type: s.type,
      };
    });
  }

  get transferMassOptions(): SelectOption[] {
    const currentLanguage = getLanguageCurrent();
    return this.massesToTransfer.map((s) => {
      const categoryName =
        s.translations?.find(
          (translation) => translation.languageCode === currentLanguage
        )?.name ?? s.translations[0]?.name;

      return {
        label: `${categoryName ?? ''}, ${s.materialName}`,
        volume: s.volume,
        value: s.id || 0,
        type: s.type,
      };
    });
  }

  async createMassReservation(
    model: CreateUpdateTransaction
  ): Promise<boolean> {
    const result = await massService.createMassReservation(model);
    return result;
  }

  async createMassOffer(model: CreateUpdateTransaction): Promise<boolean> {
    const result = await massService.createMassOffer(model);
    return result;
  }

  async getActivityRegisteredList(model: RegisteredPagination): Promise<void> {
    this.activityRegisteredList = [];
    this.totalActivityRegisteredList = 0;
    const result = await massService.getActivityRegisteredList(model);
    this.activityRegisteredList = result.data;
    this.totalActivityRegisteredList = result.count;
  }

  async searchActivityRegisteredLink(
    model: RegisteredPagination
  ): Promise<ActivitiesResult[]> {
    const result = await massService.getActivityRegisteredList(model);
    return result.data;
  }

  get activitiesRegistered(): Map<number, ActivitiesResult[]> {
    const map = new Map<number, ActivitiesResult[]>();
    this.activityRegisteredList.map((item: ActivitiesResult) => {
      const key = resetTimeZeroOfDate(item.createdAt).getTime();
      const olds = map.get(key);
      if (olds) {
        map.set(key, [...olds, item]);
      } else {
        map.set(key, [item]);
      }
      return item;
    });
    return map;
  }

  async getActivityPlannedList(model: TransactionPagination): Promise<void> {
    this.activityPlannedList = [];
    this.totalActivityPlannedList = 0;
    const result = await massService.getActivityPlannedList(model);
    this.activityPlannedList = result.data;
    this.totalActivityPlannedList = result.count;
  }

  get activitiesPlanned(): Map<number, ActivitiesResult[]> {
    const map = new Map<number, ActivitiesResult[]>();
    this.activityPlannedList.map((item: ActivitiesResult) => {
      const key = resetTimeZeroOfDate(
        item.transportDate ?? new Date()
      ).getTime();
      const olds = map.get(key);
      if (olds) {
        map.set(key, [...olds, item]);
      } else {
        map.set(key, [item]);
      }
      return item;
    });
    return map;
  }

  async getActivityCompletedList(model: TransactionPagination): Promise<void> {
    this.activityCompletedList = [];
    this.totalActivityCompletedList = 0;
    const result = await massService.getActivityCompletedList(model);
    this.activityCompletedList = result.data;
    this.totalActivityCompletedList = result.count;
  }

  get activitiesCompleted(): Map<number, ActivitiesResult[]> {
    const map = new Map<number, ActivitiesResult[]>();
    this.activityCompletedList.map((item: ActivitiesResult) => {
      const key = resetTimeZeroOfDate(
        item.transportDate ?? new Date()
      ).getTime();
      const olds = map.get(key);
      if (olds) {
        map.set(key, [...olds, item]);
      } else {
        map.set(key, [item]);
      }
      return item;
    });
    return map;
  }

  async getRelatedTransactions(
    massId: number,
    model?: TransactionPagination
  ): Promise<void> {
    this.relatedTransactions = [];
    this.numberOfRelatedTransactions = 0;
    const result = await massService.getRelatedTransactions(massId, model);
    this.relatedTransactions = result.data;
    this.numberOfRelatedTransactions = result.count;
  }

  async getViewMassTransaction(
    id: number,
    type: ACTIVITIES_RESULT_TYPE
  ): Promise<ActivitiesResult> {
    const result = await massService.getViewMassTransaction(id, type);
    return result;
  }

  async getMassByProjectId(
    projectId: number
  ): Promise<Array<Supply | Request>> {
    const result = await massService.getMassByProjectId(projectId);
    return result;
  }

  async searchMassToTransfer(
    projectId: number,
    searchKey: string
  ): Promise<Supply[]> {
    const result = await massService.searchMassesToTransfer(
      projectId,
      searchKey
    );
    this.massesToTransfer = result;
    return result;
  }

  async getTransactionByMassId(
    massId: number,
    isSupply: boolean
  ): Promise<Array<CreateUpdateTransaction>> {
    const result = await massService.getTransactionByMassId(massId, isSupply);
    return result;
  }
}

export default MassStore;
