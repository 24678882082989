import companyService from '@/apis/company.service';
import { INIT_COMPANY_PERMISSION_TABLE } from '@/constants/company.constants';
import { INIT_LOCATION } from '@/constants/location.constants';
import {
  CreateInfoCompany,
  InfoCompany,
} from '@/dto/companies/InfoCompany.dto';
import { PaginationRequest } from '@dto/commons/PaginationRequest.dto';
import { action, makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';

class CompanyStore {
  companies: InfoCompany[];
  companiesCount: number;
  currentCompany: InfoCompany;
  currentCompanyInit: InfoCompany = {
    id: 0,
    companyName: '',
    website: '',
    title: '',
    description: '',
    assetImage: null,
    assetLogo: null,
    companyAssets: [],
    phoneNumber: '',
    contactSales: '',
    contactPrimary: '',
    location: INIT_LOCATION,
    ownerId: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    permissions: INIT_COMPANY_PERMISSION_TABLE,
  };

  constructor() {
    this.companies = [];
    this.companiesCount = 0;
    this.currentCompany = this.currentCompanyInit;

    makeObservable(this, {
      companies: observable,
      companiesCount: observable,
      currentCompany: observable,
      getCompanies: action,
      getCompanyById: action,
      submitCompanyForm: action,
      initCurrentCompany: action,
    });
  }

  async getCompanies(query?: PaginationRequest): Promise<void> {
    const data = await companyService.getCompanies(query);
    this.companies = data.data;
    this.companiesCount = data.count;
  }

  async getCompanyById(id: number): Promise<void> {
    this.currentCompany = await companyService.getCompanyById(id);
  }

  submitCompanyForm = async (
    id: number,
    company: InfoCompany,
    imageFile: File | null,
    logoFile: File | null
  ): Promise<boolean> => {
    const result = await companyService.updateCompanyById(
      id,
      company,
      imageFile,
      logoFile
    );
    if (result) {
      this.currentCompany = company;
      toast.success('Company saved');
      return true;
    } else {
      toast.error('Error when saving company');
      return false;
    }
  };

  createCompanyForm = async (
    company: CreateInfoCompany,
    imageFile: File | null,
    logoFile: File | null
  ): Promise<boolean> => {
    company.id = null;
    const result = await companyService.createCompany(
      company,
      imageFile,
      logoFile
    );
    if (result) {
      toast.success('Company created');
      return true;
    } else {
      toast.error('Error when creating company');
      return false;
    }
  };

  resetCompanyForm = async (companyId: number): Promise<void> => {
    await this.getCompanyById(companyId);
  };

  initCurrentCompany = () => {
    this.currentCompany = this.currentCompanyInit;
  };

  async lookupCompanyByOrgNumber(orgNumber: string): Promise<any> {
    return await companyService.lookupCompanyByOrgNumber(orgNumber);
  }
}

export default CompanyStore;
