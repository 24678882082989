import http from '@/apis';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { InfoTrip } from '@/dto/trip/InfoTrip.dto';
import { MASS_TYPE } from '@enums/mass.enum';
import { prepareGetQuery } from '@utils/api.utils';

class TripService {
  prefix = 'trip';

  public async registerTrip(
    model: InfoTrip,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();

    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const result = await http.post(`${this.prefix}`, form, config);

    return result.data.result;
  }

  public async getTripById(id: number): Promise<InfoTrip> {
    const result = await http.get(`${this.prefix}/${id}`);
    return result.data.result;
  }

  public async getTripsByProject(
    projectId: number,
    query: PaginationRequest<InfoTrip>
  ): Promise<PaginationResult<InfoTrip>> {
    const result = await http.get(
      `${this.prefix}/project/${projectId}/${prepareGetQuery(
        query as Record<string, unknown>
      )}`
    );
    const trips = result.data.result.data;
    const tripCount = result.data.result.count;

    return { data: trips, count: tripCount };
  }

  public async getTripsByUser(
    query: PaginationRequest<InfoTrip>
  ): Promise<PaginationResult<InfoTrip>> {
    const result = await http.get(
      `${this.prefix}/user${prepareGetQuery(query as Record<string, unknown>)}`
    );
    const trips = result.data.result.data;
    const tripCount = result.data.result.count;

    return { data: trips, count: tripCount };
  }

  public async getLatestTripsByMass(
    massId: number,
    massType: MASS_TYPE
  ): Promise<InfoTrip[]> {
    const result = await http.get(
      `${this.prefix}/latest/${massType}/${massId}`
    );
    return result.data.result;
  }

  public async updateTrip(
    model: InfoTrip,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();

    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(`${this.prefix}/${model.id}`, form, config);
    return result.data.result;
  }

  public async getAllTransactionTrips(
    id: number
  ): Promise<PaginationResult<InfoTrip>> {
    const result = await http.get(`${this.prefix}/${id}`);
    return result.data.result;
  }
}

export default new TripService();
