import http from '@/apis';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import {
  SettingsMaterial,
  SettingsMaterialApi,
  SettingsMaterialApiTranslation,
} from '@/dto/settings/SettingsMaterial.dto';
import { SettingsMaterialCreate } from '@/dto/settings/SettingsMaterialCreate.dto';
import { LANGUAGE } from '@/enums/lang.enum';
import { prepareGetQuery } from '@utils/api.utils';
import { v4 as uuidv4 } from 'uuid';

class MaterialService {
  prefix = 'materials';
  prefixAdmin = 'admin/' + this.prefix;

  public async getMaterials(
    languageCode: string,
    query: PaginationRequest
  ): Promise<PaginationResult<SettingsMaterial>> {
    const result = !!query
      ? await http.get(
          `${this.prefix}/${prepareGetQuery(query as Record<string, unknown>)}`
        )
      : await http.get(`${this.prefix}`);
    const materials = result.data.result.data;
    const materialCount = result.data.result.count;
    const translations = materials.map((material: SettingsMaterialApi) => {
      let translation = material.translations.filter(
        (translation: SettingsMaterialApiTranslation) =>
          translation.languageCode === languageCode
      )[0];
      if (!translation) {
        translation = material.translations.filter(
          (translation: SettingsMaterialApiTranslation) =>
            translation.languageCode === 'no'
        )[0];
      }

      return {
        isActive: material.isActive,
        id: material.id,
        alternativeName: material.alternativeName,
        sizeFrom: material.sizeFrom,
        sizeTo: material.sizeTo,
        name: translation?.name ?? material.alternativeName,
        description: translation?.description,
        updatedAt: material.updatedAt,
        translations: material.translations,
        ealCodes: material.ealCodes,
        nsCodes: material.nsCodes,
      };
    });

    return { data: translations, count: materialCount };
  }

  public async getMaterial(
    id: number,
    languageCode: string
  ): Promise<SettingsMaterial | null> {
    const result = await http.get(`${this.prefix}/${id}`);

    const material = result.data.result;
    const translation = material.translations.filter(
      (translation: SettingsMaterialApiTranslation) =>
        translation.languageCode === languageCode
    )[0];
    if (!translation) return null;
    return {
      isActive: material.isActive,
      id: material.id,
      alternativeName: material.alternativeName,
      sizeFrom: material.sizeFrom,
      sizeTo: material.sizeTo,
      name: translation.name,
      description: translation.description,
      updatedAt: material.updatedAt,
      table: 'material',
      languageCode: translation.languageCode,
      massRequest: material.massRequest,
      massSupply: material.massSupply,
      ealCodes: material.ealCodes,
      nsCodes: material.nsCodes,
      isWaste: material.isWaste,
      isHazardous: material.isHazardous,
      translations: material.translations,
      particleSizes: material.particleSizes ?? [
        { id: uuidv4(), sizeFrom: 0, sizeTo: 0 },
      ],
    };
  }

  public async createMaterial(model: SettingsMaterialCreate): Promise<boolean> {
    const result = await http.post(`${this.prefixAdmin}`, model);
    return result.data.result.data;
  }

  public async editMaterial(model: SettingsMaterial): Promise<boolean> {
    const result = await http.put(`${this.prefixAdmin}/${model.id}`, model);
    const isSuccess = result.data.success;

    return isSuccess;
  }

  public async deleteMaterial(id: number): Promise<boolean> {
    const result = await http.delete(`${this.prefixAdmin}/${id}`);
    return result.data.result.data;
  }

  public async getMaterialsMinified(
    query: PaginationRequest<SettingsMaterial>
  ) {
    let url = `${this.prefix}/minified`;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);

    return result.data.result.data;
  }

  public async minified(languageCode: string) {
    const result = await http.get(`${this.prefix}/minified`);
    const materials = result.data.result.data;

    const localizedMaterials = materials.map(
      (material: SettingsMaterialApi) => {
        const translation = material.translations.filter(
          (translation: SettingsMaterialApiTranslation) =>
            translation.languageCode === languageCode ||
            translation.languageCode === LANGUAGE.NORWAY
        )[0];

        return {
          isActive: material.isActive,
          id: material.id,
          alternativeName: material.alternativeName,
          sizeFrom: material.sizeFrom,
          sizeTo: material.sizeTo,
          name: translation.name,
          description: translation.description,
          updatedAt: material.updatedAt,
          translations: material.translations,
        };
      }
    );

    return localizedMaterials.sort(
      (a: SettingsMaterialApiTranslation, b: SettingsMaterialApiTranslation) =>
        a.name.localeCompare(b.name)
    );
  }
}

export default new MaterialService();
